import React, { Component } from "react"

// RangeSlider
import Nouislider from "nouislider-react"
import "nouislider/distribute/nouislider.css"

class RateSlider extends Component {

  onChange = (render, handle, sliderValue) =>{
    let { integer = true} = this.props;
    if(integer){
      sliderValue[0] = parseInt(sliderValue[0]);
      sliderValue[1] = parseInt(sliderValue[1]);
    }
    
    if(typeof this.props.onChange != undefined){
      this.props.onChange(render, handle, sliderValue);
    }
  }

  render() {
    let minRate = this.props.minRate || 40;
    let maxRate = this.props.maxRate || 80;
    let sliderMinRate = 10;
    let sliderMaxRate = this.props.sliderMaxRate || 300;
    let smallStep = 10;
    let bigStep = 25;

    return (
      <React.Fragment>
        <Nouislider
          range={{ min: sliderMinRate, '1%': [sliderMinRate, smallStep], '50%': [100, bigStep], max: sliderMaxRate }}
          tooltips={true}
          start={[minRate, maxRate]}
          connect
          onChange={this.onChange}
        />
      </React.Fragment>
    )
  }
}

export default RateSlider
