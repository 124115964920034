import React, { Component } from 'react'
import { connect } from "react-redux"
import { Link, Redirect } from 'react-router-dom';

import { Dropdown, DropdownButton } from "react-bootstrap";

import { Button, Card, CardBody, CardTitle, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap';

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import { createTimelineStatuses } from 'pages/students/tutor-match-process/scripts'
import TutorRequestDetails from 'pages/students/tutor-match-process/TutorRequestDetails'

import BTNMetaTags from "components/metatags/BTNMetaTags"
import RateSlider from 'components/slider/RateSlider';
import ThemeSkeleton from 'components/skeleton/ThemeSkeleton'
import VerticalTimeline from 'components/timeline/VerticalTimeline'

import { ThemeSpinner } from 'theme/elements/spinner'

import MarketProposalDetailsCard from './MarketProposalDetailsCard';
import MarketProposalSummaryCard from './MarketProposalSummaryCard';
import TutorRequestProgressTour from './TutorRequestProgressTour';

import { deadlineHasPassed, proposalFilterOptions, proposalSortOptions } from './proposalFilterOptions'
import { CHAT_INBOX, STUDENT_MARKETPLACE_HOW_IT_WORKS, STUDENT_TUTOR_PROPOSAL_SCHOLARSHIP_CONFIRMATION, STUDENT_TUTOR_PROPOSAL_SELECT_PACKAGE, TUTOR_PROFILE } from 'routes/paths';
import ThemeAvatar from 'theme/images/ThemeAvatar';
import TutorStarRating from 'components/ratings/TutorStarRating';
import { replaceAllLinks } from 'scripts/chat';
import { formatDateTime, getCurrencySymbol } from 'scripts/general';
import moment from 'moment';

class MarketTutorRequestProgress extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            tutorRequestId: props.match.params.tutorRequestId,
            tutorRequest: {},
            selectedFilterOption: 1,
            filteredProposals: [],
            filterOnline: true,
            filterInPerson: true,
            filterOptions: proposalFilterOptions,
            
            removedProposalIds: [],

            filterMinRate: 10,
            filterMaxRate: 200,

            selectedSortOption: 1,
            sortOptions: proposalSortOptions,

            showProposal: false,
            selectedProposal: {},

            contractId: null,
            redirect: false
        }

        this.submitProposalDecision.bind(this);
    }
    
    componentDidMount() {
        let { tutorRequestId } = this.state;
        let { onSubmitApiCall } = this.props;
        onSubmitApiCall("getTutorRequestWithProposals", tutorRequestId);
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
            }
        }
    }

    onChangeRate = (render, handle, sliderValue) => {
        let filterMinRate = sliderValue[0];
        let filterMaxRate = sliderValue[1];
        this.setState({ filterMinRate, filterMaxRate });
    }

    onShowDetails = (proposalId = null) => {
        let { tutorRequest  } = this.state;
        let { proposals=[] } = tutorRequest;
        
        let selectedProposal = proposals.find(proposal => proposal.id ==proposalId);

        this.setState({selectedProposal, showDetails: true});
    }

    sortProposals = sortId => {
        // let { filteredProposals, sortOptions } = this.state;
        // let proposalsList = [...filteredProposals];

        // let selectedSortOption = sortOptions.find(option => option.id == sortId);
        
        // let sortedProposals = selectedSortOption.sort(proposalsList);

        // this.setState({
        //     selectedSortOption: sortId,
        //     filteredProposals: sortedProposals
        // });

    }

    filterInPersonProposals = proposals => {
        return proposals.filter(proposal => proposal.inPerson == true);
    }

    filterOnlineProposals = proposals => {
        return proposals.filter(proposal => proposal.online == true);
    }

    filterByRate = proposals => {
        let { filterMinRate, filterMaxRate } = this.state;

        return proposals.filter(proposal => proposal.rate >= filterMinRate && proposal.rate <= filterMaxRate)
    }

    filterProposals = eventKey => {
        let { tutorRequest, filterOptions } = this.state;
        let { proposals=[] } = tutorRequest;

        let proposalsList = [...proposals];
        let selectedFilterOption = filterOptions.find(option => option.id == eventKey)

        proposalsList = proposalsList.filter(proposal => selectedFilterOption.filter(proposal));

        this.setState({
            selectedFilterOption: selectedFilterOption.id,
            filteredProposals: proposalsList
        });
    }

    submitProposalDecision = async (formData) => {
        const { onSubmitApiCall } = this.props;
        let { tutorRequest, removedProposalIds } = this.state;
        let { decision, rejectReason, proposalId } = formData;
        
        //Check if contract exists
        let { proposals } = tutorRequest;
        let proposal = proposals.find(proposal => proposal.id == proposalId);

        // Submit Proposal Decision If Contract Does Not Exist
        if(decision){
            if(proposal && proposal.contractId){
                this.setState({contractId: proposal.contractId, redirect: true});
                return;
            }

            await onSubmitApiCall("acceptProposalCreateContract", proposalId);
            this.setState({redirect: true});
        }
        else{
            rejectReason = rejectReason != null && rejectReason.trim() != "" ? rejectReason.trim() : null;
            onSubmitApiCall("rejectProposal", {proposalId, rejectReason});

            removedProposalIds.push(proposal.id);
            this.setState({ removedProposalIds, showDetails: false });
        }
    }

    render() {
        let { tutorRequest, filteredProposals=[], filterOptions, selectedFilterOption,
                filterInPerson, filterOnline, sortOptions, selectedSortOption,
                removedProposalIds=[], 
                selectedProposal, showDetails,
                contractId, redirect } = this.state;
        let { apiResponse } = this.props;

        //First loading, load all proposals
        if(selectedFilterOption == 1 && filteredProposals.length == 0){
            filteredProposals = tutorRequest.proposals || [];
        }

        let loading = apiResponse.loading;
        let timelineStatuses = createTimelineStatuses(tutorRequest);

        let selectedTitle = filterOptions.find(opt => opt.id == selectedFilterOption).title;

        //Filter inPerson & online
        if( filterOnline == false || filterInPerson == false){
            if(filterOnline == false && filterInPerson == false){
                filteredProposals = [];
            }
            else{
                filteredProposals = filterInPerson ? this.filterInPersonProposals(filteredProposals) : this.filterOnlineProposals(filteredProposals);
            }
        }
        
        filteredProposals = filteredProposals.filter(proposal => !removedProposalIds.includes(proposal.id));
        filteredProposals = this.filterByRate(filteredProposals);
        
        //Sort Proposals
        let selectedSortOptionObject = sortOptions.find(option => option.id == selectedSortOption);
        let selectedSortTitle = selectedSortOptionObject.title;
        filteredProposals.sort(selectedSortOptionObject.sort);

        if(redirect && (selectedProposal.contractId != null || contractId != null)){
            let redirectURL = tutorRequest.scholarship ? STUDENT_TUTOR_PROPOSAL_SCHOLARSHIP_CONFIRMATION : STUDENT_TUTOR_PROPOSAL_SELECT_PACKAGE;
            redirectURL += "/" + contractId;
            return  <Redirect to={redirectURL} />
        } 

        return (
            <>
                <>
                    <BTNMetaTags title="Tutor Request Progress" />
                    {tutorRequest.id != null && <TutorRequestProgressTour run={true}/>}
                    <br />
                    <Row>
                        <Col md={4} sm={8}>
                            {
                                tutorRequest.id == null
                                    ?   
                                        <Card>
                                            <CardBody>
                                                <CardTitle>Tutor Request Details</CardTitle>
                                                <br /><br />
                                                <center><ThemeSpinner /></center>
                                                <br /><br />
                                            </CardBody>
                                        </Card>
                                    :   
                                        <TutorRequestDetails key={"request-" + tutorRequest.id == null ? "" : tutorRequest.id} tutorRequest={tutorRequest}/>
                            }
                        </Col>
                        <Col xl={7} md={8} sm={12}  id="progress-timeline">
                            <Card style={{border: "1px solid lightgray"}}>
                                <CardBody>
                                    <CardTitle>Tutor Request Progress</CardTitle>
                                    <Link to={STUDENT_MARKETPLACE_HOW_IT_WORKS} className="btn btn-info">How It Works</Link>
                                    <br /><br />
                                    {
                                        tutorRequest.id == null
                                            ?   <ThemeSkeleton />
                                            :   <VerticalTimeline key={"vtime-" + tutorRequest.id} statuses={timelineStatuses}/>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <Col sm={12} md={12} lg={12} xl={{offset: 1, size: 10}}>
                                    <Card style={{border: "1px solid lightgray"}}>
                                        <CardBody className="pr-5 pl-5">
                                            <CardTitle>Tutor Proposals</CardTitle>
                                            <h4>{filteredProposals.length} {filteredProposals.length == 1 ? "Tutor" : "Tutors"}</h4>
                                            {/* <p>Click on each tutor to view details.</p> */}
                                            {loading && tutorRequest.id == null 
                                                ?
                                                    <ThemeSkeleton />
                                                :
                                                    <Row>
                                                        <Col sm="5" xs="12" className="d-flex mb-4">
                                                            <DropdownButton
                                                                className="border-0 f-16 p-0 min-w-150 cut-texts"
                                                                variant="outline-default"
                                                                onSelect={this.filterProposals}
                                                                title={"Filter: " + selectedTitle}
                                                                >
                                                                {
                                                                    filterOptions.map((option, i) =>
                                                                        <Dropdown.Item
                                                                            key={"dropdown-"+i}
                                                                            eventKey={option.id}
                                                                            active={selectedFilterOption === option.id}
                                                                        >
                                                                            <span>{option.title}</span>
                                                                        </Dropdown.Item>
                                                                    )
                                                                }
                                                            </DropdownButton>

                                                            <DropdownButton
                                                                className="border-0 f-16 p-0 min-w-150 cut-texts"
                                                                variant="outline-default"
                                                                onSelect={(eventKey) => this.setState({selectedSortOption: eventKey})}
                                                                title={"Sort: " + selectedSortTitle}
                                                                >
                                                                {
                                                                    sortOptions.map((sortOpt, i) =>
                                                                        <Dropdown.Item
                                                                            key={"dropdown-sort-"+i}
                                                                            eventKey={sortOpt.id}
                                                                            active={selectedSortOption === sortOpt.id}
                                                                        >
                                                                            <span>{sortOpt.title}</span>
                                                                        </Dropdown.Item>
                                                                    )
                                                                }
                                                            </DropdownButton>
                                                            <br />
                                                        </Col>
                                                        <Col sm="3" xs="6">
                                                            <div className="custom-control custom-checkbox mb-3">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="inPerson"
                                                                    name="inPerson"
                                                                    value={filterInPerson}
                                                                    checked={filterInPerson}
                                                                    onChange={() => this.setState({filterInPerson: !filterInPerson})}
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="inPerson"
                                                                >
                                                                    In-Person Tutors
                                                                </label>
                                                            </div>
                                                            </Col>
                                                            <Col sm="3" xs="6">
                                                            <div className="custom-control custom-checkbox mb-3">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="online"
                                                                    name="online"
                                                                    value={filterOnline}
                                                                    checked={filterOnline == true}
                                                                    onChange={() => this.setState({filterOnline: !filterOnline})}
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="online"
                                                                >
                                                                    Online Tutors
                                                                </label>
                                                            </div>
                                                            <br />
                                                        </Col>
                                                        <Col sm="4" xs={{size: 8, offset: 1}}>
                                                                <br /><br />
                                                                <RateSlider onChange={this.onChangeRate} minRate={10} maxRate={300} />
                                                                <center><label className="mt-2">Hourly Rate</label></center>
                                                        </Col>
                                                    </Row>
                                            }
                                        
                                            {/* <Row>
                                                {
                                                    filteredProposals.map((proposal, i) =>
                                                        <Col  key={i} sm="4" md="3" xs={{offset: 1, size: 10}}>
                                                            <MarketProposalSummaryCard proposal={proposal} onShowDetails={this.onShowDetails} />
                                                        </Col>
                                                    )
                                                }
                                            </Row>  */}
                                            
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>   
                            <Row>
                                <Col sm={12} md={12} lg={12} xl={{offset: 1, size: 10}}>
                                    {
                                        filteredProposals.map((proposal, i) =>
                                            <div key={"div-proposals-" + i} style={deadlineHasPassed(proposal) == true || proposal.studentDecision == false ? {backgroundColor: "#f0f0f0", border: "1px lightgray solid", borderRadius: "5px"} : {backgroundColor: "white", border: "1px lightgray solid", borderRadius: "5px"}} className="p-4 mb-3 shadow-sm">
                                                <div className="d-lg-flex">
                                                    <div className='d-flex'>
                                                        {
                                                            proposal.tutorAvatar && 
                                                            <div>
                                                                <ThemeAvatar avatar={proposal.tutorAvatar} name={proposal.tutorName} size="md" rounded="rounded" />    
                                                            </div>
                                                        }
                                                        
                                                        <div className="d-flex flex-column mb-2">
                                                            <div>
                                                                <h5 className="text-dark mb-1">
                                                                    <Link to={TUTOR_PROFILE + "/" + proposal.tutorId}  target="_blank">
                                                                        {proposal.tutorName}
                                                                    </Link>
                                                                </h5>
                                                            </div>
                                                            
                                                            <div>
                                                                <h4 style={{fontWeight: "600"}}>
                                                                        {proposal.education} - {proposal.major}
                                                                </h4>
                                                            </div>
                                                            <div style={{fontSize: "1rem"}}>
                                                                <span className="text-secondary mb-1 mb-lg-3 mr-2" style={{fontWeight: 600}}>
                                                                    {getCurrencySymbol(proposal.currency || "usd")}{proposal.rate}/hr
                                                                </span>

                                                                {
                                                                    proposal.inPerson == true &&
                                                                    <span className="mr-2"> &#10003; In Person Tutoring</span>
                                                                }

                                                                {
                                                                    proposal.online == true &&
                                                                    <span>&#10003; Online Tutoring</span>
                                                                }
                                                            </div>
                                                            
                                                            <div className="mt-1">
                                                                {
                                                                    proposal.reviewsCount > 0 && 
                                                                        <TutorStarRating rating={proposal.tutorRating} reviewsCount={proposal.reviewsCount} />
                                                                }
                                                            </div>     
                                                        </div> 
                                                    </div>
                                                    <div className='ml-auto d-flex flex-column'>
                                                        <div>
                                                        {
                                                            deadlineHasPassed(proposal) || loading
                                                            ?
                                                                <Button color="dark"
                                                                    outline
                                                                    className="btn-md w-md mr-2 ml-2 mb-1"
                                                                    disabled={true}>
                                                                    Accept Proposal
                                                                    {/* <br />
                                                                    <small>Prepay 1st Hour</small> */}
                                                                </Button>
                                                            :
                                                            <Button color="dark"
                                                                    className="btn-md w-md mr-2 ml-2 mb-1"
                                                                    onClick={() => this.submitProposalDecision({decision: true, proposalId: proposal.id, rejectReason: ""})}>
                                                                &#10003; Accept Proposal
                                                                {/* <br />
                                                                <small>Prepay 1st Hour</small> */}
                                                            </Button>
                                                        }
                                                        {
                                                            deadlineHasPassed(proposal) || loading
                                                            ?
                                                                <Button color="secondary"
                                                                    outline
                                                                    className="btn-md w-md mr-2 ml-2 mb-1"
                                                                    disabled={true}>
                                                                    {proposal.studentDecision !== false ? "Decline Proposal" : "Declined"}
                                                                </Button>
                                                            :
                                                                <Button color="secondary"
                                                                    className="btn-md w-md mr-2 ml-2 mb-1"
                                                                    onClick={() => this.submitProposalDecision({decision: false, proposalId: proposal.id, rejectReason: ""})}
                                                                    outline={proposal.studentDecision !== false  ? false : true}
                                                                    disabled={proposal.studentDecision !== false  ? false : true}>
                                                                        &#8855; {proposal.studentDecision !== false ? "Decline Proposal" : "Declined"}
                                                                </Button>
                                                        }
                                                        </div>
                                                        {
                                                            proposal.studentDeadline &&
                                                                <div className="mt-2 ml-2">
                                                                        <i className="bx bx-calendar mr-1 text-primary" />
                                                                        Respond By:{" "}
                                                                        {
                                                                            moment().diff(proposal.studentDeadline, 'minutes') > 0 
                                                                            ? <span className={"text-danger"}>{moment(proposal.studentDeadline).fromNow()}</span>
                                                                            : <span className={"text-dark"}>{formatDateTime(proposal.studentDeadline)}</span>

                                                                        }
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="mt-3">
                                                    <p className="font-size-15">
                                                        {proposal.experience} {" "}
                                                        <Link to={TUTOR_PROFILE + "/" + proposal.tutorId} className="text-primary" target="_blank">
                                                            View {proposal.tutorName}'s Profile
                                                        </Link>
                                                    </p>
                                                </div>
                                                <div>
                                                    {
                                                        proposal.message && proposal.threadId != null && proposal.threadId != "" && 
                                                        <div className='d-flex'>
                                                            <div style={{border: "1px solid gray", borderRadius: "5px", maxWidth: "550px"}} className="p-2 pr-3 pl-3">
                                                                <center className="text-dark" style={{fontSize: "16px", fontWeight: ""}}>
                                                                    "{replaceAllLinks(proposal.message) + "..."}"
                                                                    <br />
                                                                    <Link to={CHAT_INBOX + "?threadId=" + proposal.threadId} className="me-2 w-md mt-2"><i className="bx bx-chat" />{" "}Chat with {proposal.tutorName}</Link>
                                                                </center>
                                                                
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        deadlineHasPassed(proposal) &&
                                                            <center className="mt-3 text-dark" style={{fontSize: "1rem"}}><b>Want to work with {proposal.tutorName}?</b> Please <a href="mailto:Contact@BuffTutor.com">email us</a> to extend the deadline to respond. </center>
                                                    }
                                                </div>
                                            </div>
                                            
                                    )}
                                </Col>
                            </Row>                        
                            {/* {
                                selectedProposal.id &&
                                <Modal
                                    isOpen={showDetails}
                                    role="dialog"
                                    autoFocus={true}
                                    centered={true}
                                    className="proposalModal"
                                    tabIndex="-1"
                                    size="lg"
                                    toggle={() => this.setState({showDetails: !showDetails})}
                                >
                                    <div className="modal-content">
                                        <ModalHeader toggle={() => this.setState({showDetails: !showDetails})}>
                                            {selectedProposal.tutorName}'s Proposal
                                        </ModalHeader>
                                        <ModalBody>
                                            <MarketProposalDetailsCard proposal={selectedProposal} onSubmitDecision={this.submitProposalDecision} submitting={loading} />
                                            {loading && <center><ThemeSpinner /></center>}
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button
                                                type="button"
                                                color="secondary"
                                                onClick={()=> {this.setState({showDetails: false})}}
                                            >
                                                Close
                                            </Button>
                                            </ModalFooter>
                                    </div>
                                </Modal>
                            } */}
                        </Col>
                    </Row>
                </>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
  })
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MarketTutorRequestProgress)
