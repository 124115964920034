import React, { Component } from 'react'
import moment from 'moment';
import { connect } from "react-redux"
import { Link } from 'react-router-dom';

import BTNMetaTags from "components/metatags/BTNMetaTags"
import { Card, CardBody, Col, Row, Table } from 'reactstrap';

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'
import ThemeSkeleton from 'components/skeleton/ThemeSkeleton';

import { CHAT_INBOX, TUTOR_MARKETPLACE_CREATE_PROPOSAL } from 'routes/paths';
import { getCurrencySymbol } from 'scripts/general';

class MarketProposalsStatus extends Component {
    constructor(props) {
        super(props)
        this.state = {
          proposals: [],
          tutorId: props.match.params.tutorId,

        }
      }

    componentDidMount() {
        let { tutorId } = this.state;
        const { onSubmitApiCall } = this.props
        onSubmitApiCall("getPendingProposalsForTutor", tutorId);
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
            }
        }
    }

    render() {
        let { proposals } = this.state;
        const { apiResponse } = this.props
        
        const loading = apiResponse.loading;
        
        return (
            <>
                <BTNMetaTags title="Pending Proposals" />
                
                <Row>
                    <Col xl={{size: 8, offset: 2}}>
                        <Card>
                            <CardBody>
                                <h3>Pending Proposals ({proposals.length})</h3>
                                <p>Listed below are proposals that you have submitted to students and their status.</p>
                                {
                                    loading
                                        ? <ThemeSkeleton />
                                        : 
                                            <Table key={"table-" + Math.random()} striped>
                                                <tbody className="p-2" style={{fontSize: "1rem"}}>
                                                    {proposals.length == 0 && <tr><td><center>No Proposals</center></td></tr>}
                                                    {proposals.map((proposal, i) =>
                                                        <tr key={"row-"+i}>
                                                            <td>
                                                                <Row>
                                                                    <Col>
                                                                        <h5>
                                                                            {proposal.studentName} - {proposal.course} {" "}
                                                                            {
                                                                                proposal.school &&
                                                                                <> @ {proposal.school}</>   
                                                                            }    
                                                                        </h5>
                                                                        <Link to={TUTOR_MARKETPLACE_CREATE_PROPOSAL + "/" + proposal.marketRequestId}>View Request Details</Link>
                                                                        <br /><br />
                                                                        <p style={{fontSize: "1rem"}}>
                                                                            <b>ID: </b> {proposal.id}<br />
                                                                            <b>Status: {proposal.status}</b><br />
                                                                            <b>Student Rate: </b>{getCurrencySymbol(proposal.studentCurrency)}{proposal.studentRate}/hr<br />
                                                                            <b>Your Earnings: </b>{getCurrencySymbol(proposal.tutorCurrency)}{proposal.tutorRate}/hr
                                                                            <br />
                                                                        
                                                                            {
                                                                                proposal.online &&
                                                                                    <span className="badge badge-soft-info">Online Tutoring</span> 
                                                                            }
                                                                            {" "}
                                                                            {
                                                                                proposal.inPerson &&
                                                                                    <span className="badge badge-soft-success">In-Person Tutoring</span>
                                                                            }
                                                                        </p>
{/*                                                                         
                                                                        {
                                                                            proposal.chatThreadId && 
                                                                                <Link to={CHAT_INBOX + "?threadId=" + proposal.chatThreadId} className="btn btn-sm btn-outline-dark"><i className="bx bx-chat"/>Send Message</Link>
                                                                        } */}
                                                                        {proposal.studentDeadline &&<b>Student Deadline: {moment(proposal.studentDeadline).fromNow()}</b>}
                                                                        <br />
                                                                    </Col>
                                                                </Row>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
  })
  
  const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(MarketProposalsStatus)
  