import React, { Component } from "react"
import { connect } from "react-redux"

import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"

import { triggerReducerLoadingAction, submitApiCallAction, submitApiCallWithReducerAction } from "store/actions"

import BackgroundCheck from "./tutorApplicantChecklist/BackgroundCheck"
import ProficiencyExamsTab from "./tutorApplicantChecklist/ProficiencyExamsTab"
import ReviewContract from "./tutorApplicantChecklist/ReviewContract"
import UploadApplicantFiles from "./tutorApplicantChecklist/UploadApplicantFiles"

import ThemeAccordion from "theme/accordion/ThemeAccordion"
import { ThemeButton } from "theme/elements/buttons"
import { ThemeSpinner } from "theme/elements/spinner"
import { LOAD_TUTOR_APPLICATION, SAVE_TUTOR_APPLICATION, TUTOR_APPLICATION_ERROR } from "store/tutorApplication/actionTypes"
import UpdateSubjectsDualList from "components/dualList/UpdateSubjectsDualList"
import BasicTour from "components/tours/BasicTour"

class TutorApplicantDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userId: this.props.userId,
      checklist: [],
      preTabs: [{id: 1, title: '1. Upload Academic Transcripts', content: <UploadApplicantFiles />, body: <UploadApplicantFiles />, isOpen: false },
              {id: 2, title: '2. Pass Proficiency Exams', content: <ProficiencyExamsTab />, body: <ProficiencyExamsTab />, isOpen: false },
      ],
      postTabs: [{id: 4, title: '4. Pass Background Check (Fee Applies)', content: <BackgroundCheck />, body: <BackgroundCheck />, isOpen: false},
                {id: 5, title: '5. Sign Contract', content: <ReviewContract />, body: <ReviewContract />, isOpen: false},
      ],
      subjectsSubmitted: false
    }
    this.onSubmit.bind(this)
  }

  componentDidMount() {
    const { user, onReducerLoading, onSubmitApiCallWithReducer } = this.props;
    onReducerLoading(LOAD_TUTOR_APPLICATION);
    onSubmitApiCallWithReducer("getTutorApplication", SAVE_TUTOR_APPLICATION, user.id, TUTOR_APPLICATION_ERROR);
  }

  onSubmit = async () => {
    const { onSubmitApiCall, tutorApplicationReducer: {tutorApplication} } = this.props;
    await onSubmitApiCall("submitTutorApplication", tutorApplication.id);

    //window.location.reload();
  }

  render() {
    let { preTabs, postTabs, subjectsSubmitted } = this.state;
    const { tutorApplicationReducer={}, apiResponse, user } = this.props;
    let loading = tutorApplicationReducer.loading;
    let tutorApplication = tutorApplicationReducer.tutorApplication || {};

    preTabs = [...preTabs, {id: 3, title: '3. Submit Package For Review',  
                            content: 
                                    <Row>
                                      <Col md={{size:10, offset: 1}}>
                                        <p style={{color:"darkred", fontWeight: "bold"}}>If the previous steps are not completed, your application will NOT be reviewed and may be automatically rejected.</p>
                                        <p>By submitting this application, I acknowledge that I will be an independent contractor position and I do <b>NOT</b> require sponsorship.</p>
                                        
                                        <ThemeButton onClick={this.onSubmit} disabled={apiResponse.loading}>
                                          Submit Application
                                        </ThemeButton>
                                      </Col>
                                    </Row>, 
                            body: 
                              <Row>
                                <Col md={{size:10, offset: 1}}>
                                  <p style={{color:"darkred", fontWeight: "bold"}}>If the previous steps are not completed, your application will NOT be reviewed and may be automatically rejected.</p>
                                  <p>By submitting this application, I acknowledge that I will be a self-employed, independent contractor and I do <b>NOT</b> require work / visa sponsorship.</p>
                                  
                                  <ThemeButton onClick={this.onSubmit} disabled={apiResponse.loading}>
                                    Submit Application
                                  </ThemeButton>
                                </Col>
                              </Row>,  
                            isOpen: false}]
    
    let subjectTourStep = [{      
                              target: 'body',
                              content: <div>Select the subjects that you want to tutor. You may be required to pass a proficiency exam for each subject. <br /><br /> <UpdateSubjectsDualList userRefId={user.id} userSubjects={[]} showReload={false} onSubmit={() => {this.setState({subjectsSubmitted: true})}} /></div>,
                                title: 'Select Subjects',
                                placement: 'center',
                                styles: {
                                  options: {
                                      //width of modal
                                      width: 600,
                                  }, 
                                  buttonClose: {
                                      //display: 'none',
                                    }},
                                    disableOverlayClose: true,
                                    locale: { last: null }
                            },];

    if(subjectsSubmitted == true && loading == false && (tutorApplication.subjects == null || tutorApplication.subjects.length == 0)){
      window.location.reload();
    }
    
    return (
      <React.Fragment>
        {tutorApplication.subjects == null || tutorApplication.subjects.length == 0 &&
          <BasicTour run={true} steps={subjectTourStep} />
        }
        <Card style={{margin: "1em"}} className='bg-dark'>
          <CardBody>
            <Card>
              <CardBody>
                <CardTitle><h3>Tutor Applicant Dashboard</h3></CardTitle>
                {
                  loading &&
                    <center><ThemeSpinner /></center>
                }
                <br />
                <p><b className="text-primary">Application Status:</b><b>{" "}{tutorApplication.status || "Not Submitted"}</b></p>
                <p style={{fontWeight: "bold"}}>Complete the following items & then submit your application for review.</p>
              </CardBody>
            </Card>
            
            <Card>
              <CardBody>
                <CardTitle><h3>Tutor Applicant Checklist</h3></CardTitle>
                <Row>
                  <Col md={{size: 8, offset: 1}}>
                    <p>Click to expand each step.</p>
                    <ThemeAccordion items={preTabs} />
                    
                    {
                      !tutorApplication.submittedAt &&
                      <>
                        {/* <Row>
                          <Col md={{size:10, offset: 1}}>
                            <p style={{color:"darkred", fontWeight: "bold"}}>If the above items are not provided, your application will NOT be reviewed.</p>
                            <p>By submitting this application, I acknowledge that I will be an independent contractor position and I do <b>NOT</b> require sponsorship.</p>
                            
                            <ThemeButton onClick={this.onSubmit} disabled={apiResponse.loading}>
                              Submit Application
                            </ThemeButton>
                          </Col>
                        </Row>
                        <br /> */}
                        <hr />
                        <p>The following steps will be active once we review and approve your application. You will receive an email alert if we choose to move forward with your applciation.<br />
                          <br /><b>Please note that you have not been hired until the steps below have been completed.</b> </p>
                      </>
                    }

                    <ThemeAccordion items={postTabs} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}
const mapStateToProps = ({ userReducer, tutorApplicationReducer, apiResponseReducer }) => ({
  user: userReducer,
  tutorApplicationReducer,
  apiResponse: apiResponseReducer
})

const mapDispatchToProps = dispatch => ({
  onReducerLoading: reducerAction => dispatch(triggerReducerLoadingAction(reducerAction)),
  onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
  onSubmitApiCallWithReducer: (method, reducerAction, data, errorAction) => dispatch(submitApiCallWithReducerAction(method, reducerAction, data, errorAction)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TutorApplicantDashboard)