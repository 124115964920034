import React, { Component } from 'react'
import moment from 'moment'
import { connect } from "react-redux"

import { Link, Redirect } from 'react-router-dom'
import { Card, CardBody, Col, Row } from 'reactstrap'

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'
import { checkAllLinkTypes } from 'scripts/chat'

import BTNMetaTags from "components/metatags/BTNMetaTags"
import MarketCreateProposalForm from 'components/forms/formik/marketplace/MarketCreateProposalForm'
import MarketRequestDetailsTable from 'components/tables/marketplace/MarketRequestDetailsTable'
import { ThemeSpinner } from 'theme/elements/spinner'
import { TUTOR_MARKETPLACE } from 'routes/paths'
import { getAppSetting } from 'scripts/app'
import MarketCalculateEarnings from 'components/tables/marketplace/MarketCalculateEarnings'


class MarketCreateProposal extends Component {
    constructor(props) {
        super(props)
        this.state = {
          marketRequestId: props.match.params.marketRequestId,
          marketRequest: {},
          submitted: false,
          btnServiceFee: 0,
          studentRate: 35,
          showCalculation: false
        }
    }

    componentDidMount() {
        const { onSubmitApiCall } = this.props;
        const { marketRequestId } = this.state;
        
        this.loadBTNServiceFee()
            .then(() => {
                onSubmitApiCall("getMarketRequest", marketRequestId)});
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
            }
        }
    }  

    loadBTNServiceFee = async () => {
        let btnServiceFee = await getAppSetting("tutor_fee");
     
        this.setState({btnServiceFee});
    }

    submitProposal = formValues => {
        if(confirm("Please confirm this action.")){
            let { onSubmitApiCall } = this.props;
            
            if(formValues.tutoringType == null || formValues.tutoringType == "" || formValues.tutoringType == [] || formValues.tutoringType.length == 0){
                alert("Please select a tutoring type.");
            }
            else{
                formValues["online"] = formValues.tutoringType.includes("online") ? true : null;
                formValues["inPerson"] = formValues.tutoringType.includes("inPerson") ? true : null;
                delete formValues.tutoringType;

                formValues["flagged"] = checkAllLinkTypes(formValues.message);
                onSubmitApiCall("createMarketProposal", formValues);
                this.setState({submitted: true});
            }
        } 
    }

    render() {
        const { apiResponse, user } = this.props;
        let { marketRequest, submitted, btnServiceFee, showCalculation } = this.state;
        
        let loading = apiResponse.loading;

        let tutorPercent = btnServiceFee * 100;

        let deadlineDays = moment().diff(marketRequest.proposalsDeadline, 'days'); //today - deadline
        let deadlineHours = moment().diff(marketRequest.proposalsDeadline, 'hours'); 
        let deadlinePast = deadlineDays > 0 ? true : false;
        let deadlineClass = "text-primary";
        
        if(deadlinePast){
            deadlineClass = "text-danger";
        }
        else if(Math.abs(deadlineHours) < 3){
            deadlineClass = "text-warning"
        }

        return (
            <>
                {
                    apiResponse.success && submitted &&
                        <Redirect to={TUTOR_MARKETPLACE + "/" + user.id} />
                }
                <BTNMetaTags title="Create A Proposal" />
                <Row>
                    <Col xl={{size: 8, offset: 2}}>
                        <Card>
                            <CardBody>
                                <Link to={TUTOR_MARKETPLACE + "/" + user.id}>&larr; Back To Tutor Requests</Link>
                                <br /><br />
                                <h3>Tutor Request Details</h3>
                                <br />
                                {
                                    loading || !marketRequest.id 
                                        ?   <>
                                                <center><h3>Loading ...</h3></center>
                                                <center><ThemeSpinner /></center>
                                            </>
                                        :
                                            <Row>
                                                <Col md="12">
                                                    <h5>
                                                        {marketRequest.studentName}
                                                    </h5>
                                                    <h4>{marketRequest.course}</h4>
                                                    <b className={deadlineClass}>
                                                        Respond By {moment(marketRequest.proposalsDeadline).format("lll")}  ({moment(marketRequest.proposalsDeadline).fromNow()})
                                                    </b>
                                                    <br /><br />
                                                    <MarketRequestDetailsTable marketRequest={marketRequest} />
                                                </Col>
                                            </Row>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xl={{size: 8, offset: 2}}>
                        <Card>
                            <CardBody>
                                {
                                    btnServiceFee <= 0 
                                        ?
                                            <>
                                                <>
                                                    <center><ThemeSpinner /></center>
                                                </>
                                            </>
                                        :
                                            <>
                                                <Row style={{fontSize: "1rem"}}>
                                                    <Col>
                                                        <h3>Create A Proposal</h3>
                                                        {/* <Link to={TUTOR_MARKETPLACE + "/" + user.id}>Back To Tutor Requests</Link> */}
                                                        <br />
                                                        <h5>Instructions</h5>
                                                        <ol>
                                                            <li>
                                                                <b>Review Tutor Request</b><br />
                                                                Carefully review the details of the tutor request and determine if you are able to can assist the student.
                                                            </li>
                                                            <br />
                                                            <li>
                                                                <b>Enter The Rate</b><br />
                                                                This is the rate that the <b>student</b> pays.  <br />
                                                                Your earnings will be the student's rate <b className="text-green">minus our {tutorPercent}% service fee</b>.<br />
                                                                {/* Use the calculator below to calculate your earnings. */}
                                                                <a className="text-primary" style={{fontSize: "1rem"}} onClick={() => this.setState({showCalculation: !showCalculation})}>{showCalculation ? <>Hide Calculation</> : <>Calculate Your Earnings</>}</a>
                                                                {
                                                                    showCalculation &&
                                                                        <Row>
                                                                            <Col md={8}>
                                                                                <MarketCalculateEarnings btnServiceFee={btnServiceFee} />
                                                                            </Col>
                                                                        </Row>
                                                                }
                                                            </li>
                                                            <br />
                                                            <li>
                                                                <b>Include Brief Message</b><br />
                                                                Include a message that introduces yourself and your past experience teaching/tutoring this particular course/class. <br/>
                                                                Feel free to add clarifying questions or any concerns/restrictions you may have.
                                                            </li>
                                                            <br />
                                                            {/* <li>Use the Chat <b className="text-warning">(TODO: Add Link)</b> feature to further discuss details (e.g. when, where, how often). </li> */}
                                                            <li>
                                                                <b>Now Begins The Waiting Game</b><br />
                                                                The student will receive proposals from multiple tutors. <br />
                                                                If the student wishes to work with you, he/she will create a match.  The student's contact information will then be listed on your Dashboard and vice versa.
                                                            </li>
                                                        </ol>

                                                        {/* <a className="text-primary" style={{fontSize: "1rem"}} onClick={() => this.setState({showCalculation: !showCalculation})}>{showCalculation ? <>Hide Calculation</> : <>Calculate Your Earnings</>}</a>
                                                        {
                                                            showCalculation &&
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <MarketCalculateEarnings btnServiceFee={btnServiceFee} />
                                                                    </Col>
                                                                </Row>
                                                        } */}
                                                    </Col>
                                                </Row>
                                            </>    
                                }
                                <br />
                                <hr />
                                <br />
                                <Row>
                                    <Col>
                                        {
                                            loading || !marketRequest.id 
                                                ?   <>
                                                        <center><h3>Loading ...</h3></center>
                                                        <center><ThemeSpinner /></center>
                                                    </>
                                                :
                                                    <Row>
                                                        <Col>
                                                            <MarketCreateProposalForm marketRequest={marketRequest} onSubmit={values => this.submitProposal({...values, marketRequestId: marketRequest.id})} />    
                                                        </Col>
                                                    </Row>
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/* <Row>
                    <Col xl={{size: 8, offset: 2}}>
                        <Card>
                            <CardBody>
                                {
                                    loading || !marketRequest.id 
                                        ?   <>
                                                <center><h3>Loading ...</h3></center>
                                                <center><ThemeSpinner /></center>
                                            </>
                                        :
                                            <Row>
                                                <Col md="6" style={{padding: "1rem"}}>
                                                    <h4>
                                                        {marketRequest.studentName} <br />
                                                        {marketRequest.course}
                                                    </h4>
                                                    <br />
                                                    <b className={deadlineClass}>
                                                        Respond By {moment(marketRequest.proposalsDeadline).format("lll")}  ({moment(marketRequest.proposalsDeadline).fromNow()})
                                                    </b>
                                                    <br /><br />
                                                    <MarketRequestDetailsTable marketRequest={marketRequest} />
                                                </Col>
                                                <Col md="6">
                                                    <MarketCreateProposalForm marketRequest={marketRequest} onSubmit={values => this.submitProposal({...values, marketRequestId: marketRequest.id})} />    
                                                </Col>
                                            </Row>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row> */}
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer, userReducer }) => ({
    apiResponse: apiResponseReducer,
    user: userReducer
})
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(MarketCreateProposal)
  